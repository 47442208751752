export function convertSizeToPrice(size) {
  if (size == "0 m² - 600 m²") {
    return 4000;
  }

  if (size === "600 m² - 1200 m²") {
    return 6500;
  }

  if (size === "1200 m² - 2000 m²") {
    return 8500;
  }

  if (size === "2000 m² - 3000 m²") {
    return 10500;
  }

  if (size === "3000 m² - 4000 m²") {
    return 12500;
  }

  if (size === "4000 m² - 5000 m²") {
    return 14500;
  }

  if (size === "5000 m² - 6000 m²") {
    return 16500;
  }

  if (size === "6000 m² - 7000 m²") {
    return 18500;
  }

  if (size === "7000 m² - 8000 m²") {
    return 20500;
  }

  if (size === "8000 m² - 9000 m²") {
    return 22500;
  }

  if (size === "9000 m² - 10000 m²") {
    return 24500;
  }

  if (size === "Mer enn 10000 m²") {
    return "Befaring kreves";
  }
}

export function convertSizeToNumberArea(size) {
  if (size == "0 m² - 600 m²") {
    return 600;
  }

  if (size === "600 m² - 1200 m²") {
    return 1200;
  }

  if (size === "1200 m² - 2000 m²") {
    return 2000;
  }

  if (size === "2000 m² - 3000 m²") {
    return 3000;
  }

  if (size === "3000 m² - 4000 m²") {
    return 4000;
  }

  if (size === "4000 m² - 5000 m²") {
    return 5000;
  }

  if (size === "5000 m² - 6000 m²") {
    return 6000;
  }

  if (size === "6000 m² - 7000 m²") {
    return 7000;
  }

  if (size === "7000 m² - 8000 m²") {
    return 8000;
  }

  if (size === "8000 m² - 9000 m²") {
    return 9000;
  }

  if (size === "9000 m² - 10000 m²") {
    return 10000;
  }

  if (size === "Mer enn 10000 m²") {
    return 11000;
  }
}

export function convertNumberOfElectricityDistributionsToPrice(
  numberOfElectricityDistributions
) {
  if (numberOfElectricityDistributions === 0) {
    return 0;
  }

  return numberOfElectricityDistributions * 1090;
}

export function getDiscount(product1, product2) {
  if (product1 === "Befaring kreves") {
    return "Befaring kreves";
  }
  const sum = product1 + product2;
  return sum * 0.25;
}

export function getFrequency(buildingType) {
  switch (buildingType) {
    case "Avfallshåndtering-/gjenvinning":
      return "Årlig";
    case "Gartneri med vekstlys":
      return "Årlig";
    case "Gatekjøkken":
      return "Årlig";
    case "Hotell/overnatting":
      return "Årlig";
    case "Industri og produksjon":
      return "Årlig";
    case "Internat":
      return "Årlig";
    case "Møller":
      return "Årlig";
    case "Pumpestasjoner tilknyttet industri eller for større anlegg":
      return "Årlig";
    case "Restaurant og servering":
      return "Årlig";
    case "Sykehus og sykehjem":
      return "Årlig";
    case "Verksted":
      return "Årlig";
    case "Verneverdig/fredet bygning (kulturminne)":
      return "Årlig";
    case "Barnehager":
      return "Hvert 3. år";
    case "Campingplasser":
      return "Hvert 3. år";
    case "Forsamlingshus":
      return "Hvert 3. år";
    case "Gartnerier med drift - uten vekstlys":
      return "Hvert 3. år";
    case "Idrettshall, svømmehall, treningssenter":
      return "Hvert 3. år";
    case "Kirker og menighetshus":
      return "Hvert 3. år";
    case "Lagerbygg":
      return "Hvert 3. år";
    case "Parkeringshus":
      return "Hvert 3. år";
    case "Skoler og skolebygg med tilhørende verksted o.l.":
      return "Hvert 3. år";
    case "Pumpestasjon - mindre":
      return "Hvert 3. år";
    case "Varehus, butikk, salgslokaler":
      return "Hvert 3. år";
    case "Vaskehall":
      return "Hvert 3. år";
    case "Kontorbygg":
      return "Hvert 3. år";
  }
}

export function getFrequencyNumber(buildingType) {
  switch (buildingType) {
    case "Avfallshåndtering-/gjenvinning":
      return 12;
    case "Gartneri med vekstlys":
      return 12;
    case "Gatekjøkken":
      return 12;
    case "Hotell/overnatting":
      return 12;
    case "Industri og produksjon":
      return 12;
    case "Internat":
      return 12;
    case "Møller":
      return 12;
    case "Pumpestasjoner tilknyttet industri eller for større anlegg":
      return 12;
    case "Restaurant og servering":
      return 12;
    case "Sykehus og sykehjem":
      return 12;
    case "Verksted":
      return 12;
    case "Verneverdig/fredet bygning (kulturminne)":
      return 12;
    case "Barnehager":
      return 36;
    case "Campingplasser":
      return 36;
    case "Forsamlingshus":
      return 36;
    case "Gartnerier med drift - uten vekstlys":
      return 36;
    case "Idrettshall, svømmehall, treningssenter":
      return 36;
    case "Kirker og menighetshus":
      return 36;
    case "Lagerbygg":
      return 36;
    case "Parkeringshus":
      return 36;
    case "Skoler og skolebygg med tilhørende verksted o.l.":
      return 36;
    case "Pumpestasjon - mindre":
      return 36;
    case "Varehus, butikk, salgslokaler":
      return 36;
    case "Vaskehall":
      return 36;
    case "Kontorbygg":
      return 36;
  }
}

export function getTotalPrice(product1, product2) {
  if (product1 === "Befaring kreves") {
    return "Befaring kreves";
  }

  const discount = getDiscount(product1, product2);
  const totalPrice = product1 + product2 - discount;
  return totalPrice;
}
