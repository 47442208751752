import Image from "next/image";
import { useState, useEffect } from "react";
import Head from "next/head";
import {
  Pane,
  Heading,
  Textarea,
  Button,
  TextInputField,
  Alert,
  Select,
  Text,
  Popover,
  Position,
  FormField,
} from "evergreen-ui";
import { addMonths, format } from "date-fns";
import logo from "../public/logo.png";
import fortum from "../public/fortum.png";
import {
  convertNumberOfElectricityDistributionsToPrice,
  convertSizeToPrice,
  getDiscount,
  getTotalPrice,
  getFrequency,
} from "../utils/price";

function useMediaQuery(query) {
  if (process && process.browser) {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
      const handler = (e) => setMatches(e.matches);
      mediaMatch.addListener(handler);
      return () => mediaMatch.removeListener(handler);
    });

    return matches;
  }

  return true;
}

function FormSuccess() {
  return (
    <Pane paddingX={10}>
      <Heading size={500}>
        Takk for din forespørsel, vi kommer tilbake til deg ved første anledning
        for å gjennomgå og iverksette en kontrollavtale
      </Heading>
      <br />
      <Heading size={500}>
        Har du noen spørsmål i mellomtiden kan du gjerne sende oss en mail til
        nk@oneco.no
      </Heading>
    </Pane>
  );
}

function Form({
  handleSubmit,
  submitting,
  error,
  success,
  setSuccess,
  setError,
}) {
  const [form, setForm] = useState({
    orgNumber: "",
    companyName: "",
    controlDate: "",
    comment: "",
    buildingType: "Avfallshåndtering-/gjenvinning",
    buildingSize: "0 m² - 600 m²",
    numberOfElectricityDistributions: 0,
    email: "",
    phoneNumber: "",
    contactPerson: "",
    address: "",
    postalCode: "",
    city: "",
  });

  return (
    <form
      style={{ width: "100%", margin: "0px 15px" }}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(form);
      }}
    >
      <TextInputField
        label="Firmanavn"
        required
        marginBottom={12}
        name="companyName"
        id="companyName"
        value={form.companyName}
        onChange={({ target }) =>
          setForm({ ...form, companyName: target.value })
        }
      />
      <TextInputField
        label="Organisasjonsnummer"
        required
        marginBottom={12}
        name="orgNumber"
        id="orgNumber"
        value={form.orgNumber}
        onChange={({ target }) => setForm({ ...form, orgNumber: target.value })}
      />
      <TextInputField
        label="Adresse"
        name="address"
        marginBottom={12}
        id="address"
        required
        value={form.address}
        onChange={({ target }) => setForm({ ...form, address: target.value })}
      />
      <TextInputField
        label="Postnummer"
        marginBottom={12}
        name="postalCode"
        id="postalCode"
        required
        value={form.postalCode}
        onChange={({ target }) =>
          setForm({ ...form, postalCode: target.value })
        }
      />
      <TextInputField
        label="By"
        name="city"
        id="city"
        required
        marginBottom={12}
        value={form.city}
        onChange={({ target }) => setForm({ ...form, city: target.value })}
      />
      <TextInputField
        label="Kontaktperson"
        name="contactPerson"
        id="contactPerson"
        required
        marginBottom={12}
        value={form.contactPerson}
        onChange={({ target }) =>
          setForm({ ...form, contactPerson: target.value })
        }
      />
      <TextInputField
        label="Telefonnummer"
        name="phoneNumber"
        id="phoneNumber"
        required
        marginBottom={12}
        value={form.phoneNumber}
        onChange={({ target }) =>
          setForm({ ...form, phoneNumber: target.value })
        }
      />
      <TextInputField
        label="Epost"
        name="email"
        id="email"
        type="email"
        marginBottom={12}
        required
        value={form.email}
        onChange={({ target }) => setForm({ ...form, email: target.value })}
      />
      <FormField
        marginBottom={12}
        width="100%"
        isRequired
        label="Størrelse på bygg"
        name="buildingSize"
        id="buildingSize"
      >
        <Select
          width="100%"
          height={36}
          backgroundColor="white"
          background="white"
          onChange={({ target }) => {
            setForm({ ...form, buildingSize: target.value });
          }}
        >
          {[
            "0 m² - 600 m²",
            "600 m² - 1200 m²",
            "1200 m² - 2000 m²",
            "2000 m² - 3000 m²",
            "3000 m² - 4000 m²",
            "4000 m² - 5000 m²",
            "5000 m² - 6000 m²",
            "6000 m² - 7000 m²",
            "7000 m² - 8000 m²",
            "8000 m² - 9000 m²",
            "9000 m² - 10000 m²",
            "Mer enn 10000 m²",
          ].map((id) => (
            <option key={id} value={id}>
              {id}
            </option>
          ))}
        </Select>
      </FormField>
      <TextInputField
        label="Antall elektriske fordelinger"
        name="numberOfElectricityDistributions"
        id="numberOfElectricityDistributions"
        description="Hvor mange sikringsskap er det på deres bygg?"
        required
        min="0"
        marginBottom={12}
        type="number"
        value={form.numberOfElectricityDistributions}
        onChange={({ target }) =>
          setForm({
            ...form,
            numberOfElectricityDistributions: target.value,
          })
        }
      />
      <TextInputField
        label="Foretrukket kontroll dato"
        autoComplete="off"
        type="date"
        id="controlDate"
        name="controlDate"
        required
        marginBottom={12}
        min={format(addMonths(new Date(), 3), "yyyy-MM-dd")}
        value={form.controlDate}
        onChange={({ target }) => {
          setForm({
            ...form,
            controlDate: target.value,
          });
        }}
      />

      <FormField
        marginBottom={12}
        width="100%"
        label="Type bygg"
        isRequired
        labelFor="buildingType"
      >
        <Select
          width="100%"
          height={36}
          backgroundColor="white"
          background="white"
          onChange={({ target }) => {
            setForm({ ...form, buildingType: target.value });
          }}
        >
          {[
            "Avfallshåndtering-/gjenvinning",
            "Gartneri med vekstlys",
            "Gatekjøkken",
            "Hotell/overnatting",
            "Industri og produksjon",
            "Internat",
            "Møller",
            "Pumpestasjoner tilknyttet industri eller for større anlegg",
            "Restaurant og servering",
            "Sykehus og sykehjem",
            "Verksted",
            "Verneverdig/fredet bygning (kulturminne)",
            "Barnehager",
            "Campingplasser",
            "Forsamlingshus",
            "Gartnerier med drift - uten vekstlys",
            "Idrettshall, svømmehall, treningssenter",
            "Kirker og menighetshus",
            "Lagerbygg",
            "Parkeringshus",
            "Skoler og skolebygg med tilhørende verksted o.l.",
            "Pumpestasjon - mindre",
            "Varehus, butikk, salgslokaler",
            "Vaskehall",
            "Kontorbygg",
          ].map((id) => (
            <option key={id} value={id}>
              {id}
            </option>
          ))}
        </Select>
        <Heading size={300} marginTop={2}>
          Frekvens: {getFrequency(form.buildingType)}
        </Heading>
      </FormField>

      <FormField
        marginBottom={12}
        width="100%"
        label="Annen info"
        labelFor="comment"
      >
        <Textarea
          id="comment"
          value={form.comment}
          onChange={({ target }) => setForm({ ...form, comment: target.value })}
        />
      </FormField>
      <Pane paddingY={20}>
        <Heading size={600} marginBottom={8}>
          Forespeilet pris
        </Heading>
        <Pane
          paddingY={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Heading>Elsjekk</Heading>
          <Heading>{`${convertSizeToPrice(form.buildingSize)}${
            convertSizeToPrice(form.buildingSize) === "Befaring kreves"
              ? ""
              : " kr"
          }
        `}</Heading>
        </Pane>
        <Pane
          paddingY={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Heading>Termografering</Heading>
          <Heading>{`${convertNumberOfElectricityDistributionsToPrice(
            form.numberOfElectricityDistributions
          )} kr`}</Heading>
        </Pane>
        <Pane
          paddingY={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Heading>Rabatt (25%)</Heading>
          <Heading>{`-${getDiscount(
            convertSizeToPrice(form.buildingSize),
            convertNumberOfElectricityDistributionsToPrice(
              form.numberOfElectricityDistributions
            )
          )}${
            convertSizeToPrice(form.buildingSize) === "Befaring kreves"
              ? ""
              : " kr"
          }
        `}</Heading>
        </Pane>
        <Pane
          paddingTop={15}
          display="flex"
          paddingBottom={2}
          flexDirection="row"
          justifyContent="space-between"
        >
          <Heading>Sum</Heading>
          <Heading>{`${getTotalPrice(
            convertSizeToPrice(form.buildingSize),
            convertNumberOfElectricityDistributionsToPrice(
              form.numberOfElectricityDistributions
            )
          )}${
            convertSizeToPrice(form.buildingSize) === "Befaring kreves"
              ? ""
              : " kr"
          }
        `}</Heading>
        </Pane>
      </Pane>

      <Popover
        position={Position.TOP}
        isShown={error || success}
        onClose={() => {
          setSuccess(false);
          setError(false);
        }}
        content={
          <Pane
            width={260}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Alert
              intent={success ? "success" : "danger"}
              title={
                success
                  ? "Forespørsel mottatt!"
                  : "Vi klarte ikke å sende inn forespørsel"
              }
            >
              {success
                ? "du vil bli kontaktet for av en kundebehandler snarest."
                : "Ser ut som det oppstod en feil, se om det er noe som ikke stemmer i skjema og prøv igjen, ta gjerne kontakt med oss om du ikke får det til."}
            </Alert>
          </Pane>
        }
      >
        <Button
          marginBottom={80}
          width="100%"
          textAlign="center"
          intent="success"
          appearance="primary"
          disabled={submitting}
          type="submit"
          style={{
            borderColor: "#711c65",
            backgroundImage: "linear-gradient(to bottom, #711c65, #711c65)",
          }}
          isLoading={submitting}
        >
          Bestill / Forespør om avtale
        </Button>
      </Popover>
    </form>
  );
}

export default function Home() {
  const isLarge = useMediaQuery("(min-width: 750px)");

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  async function handleSubmit(form) {
    setSubmitting(true);

    try {
      const response = await fetch("/api/form", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });

      if (response.status >= 300) {
        throw new Error();
      }

      setSubmitting(false);
      setSuccess(true);
    } catch (error) {
      setSubmitting(false);
      setError(true);
    }
  }

  return (
    <>
      <Head>
        <title>Fortum El-siker | oneco</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="Description"
          content="Fortum EL-sikker | powered by Oneco"
        />
      </Head>

      <Pane
        width="100%"
        background="#711c65"
        margin={0}
        padding={0}
        height={12}
      />
      <Pane width="100%" paddingX={15}>
        <Pane
          paddingTop={20}
          maxWidth={1000}
          marginX="auto"
          height={230}
          display="flex"
          flexDirection="column"
        >
          <Pane display="flex" flexDirection="row" alignItems="center">
            <Pane width="60%">
              <Image alt="Sonnico logo" src={logo} />
            </Pane>
            <Pane width="40%">
              <Heading
                fontSize={isLarge ? 40 : 15}
                marginLeft={10}
                marginBottom={isLarge ? -990 : -10}
              >
                ELSJEKK
              </Heading>
            </Pane>
          </Pane>
          <Pane
            display="flex"
            flexDirection="row"
            alignContent="space-between"
            alignItems="center"
            marginTop={10}
            width="100%"
          >
            <Pane
              display="flex"
              flexDirection="row"
              alignItems="center"
              width={isLarge ? "50%" : "100%"}
            >
              <Pane width="30%">
                <Heading marginRight={10}>En tjeneste gjennom</Heading>
              </Pane>
              <Pane width="50%">
                <Image alt="Sonnico logo" src={fortum} />
              </Pane>
              <Pane width="30%">
                <Heading marginLeft={10} paddingY={0}>
                  EL-SIKKER
                </Heading>
              </Pane>
            </Pane>
            {isLarge && (
              <Pane width="40%" marginTop={10}>
                <a
                  target="_blank"
                  href="https://www.sonnico.no/internkontroll-elektro"
                >
                  <Button
                    textAlign="center"
                    intent="success"
                    appearance="primary"
                    style={{
                      borderColor: "#711c65",
                      backgroundImage:
                        "linear-gradient(to bottom, #711c65, #711c65)",
                    }}
                  >
                    Hvorfor Elsjekk?
                  </Button>
                </a>
              </Pane>
            )}
          </Pane>
          {!isLarge && (
            <Pane width="40%" marginTop={20}>
              <a
                target="_blank"
                href="https://www.sonnico.no/internkontroll-elektro"
              >
                <Button
                  textAlign="center"
                  intent="success"
                  appearance="primary"
                  style={{
                    borderColor: "#711c65",
                    backgroundImage:
                      "linear-gradient(to bottom, #711c65, #711c65)",
                  }}
                >
                  Hvorfor Elsjekk?
                </Button>
              </a>
            </Pane>
          )}
        </Pane>
      </Pane>
      <Pane width="100%">
        <Pane
          paddingY={15}
          maxWidth={1000}
          width="100%"
          marginX="auto"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {success ? (
            <FormSuccess />
          ) : (
            <Form
              handleSubmit={handleSubmit}
              submitting={submitting}
              success={success}
              error={error}
              setSuccess={setSuccess}
              setError={setError}
            />
          )}
        </Pane>
      </Pane>
    </>
  );
}
